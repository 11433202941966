import React, { useEffect, useState } from "react";
import Headermobile from "./Headermobile";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import M1 from "../images/M1.webp";
import M2 from "../images/M2.webp";
import M3 from "../images/M3.webp";
import ReactPlayer from "react-player";


import MM1 from "../images/MM1.webp";
import MM2 from "../images/MM2.webp";

import HH from "../images/Horoscope.webp";
import { baseurl } from "../Component/Baseurl";
// import Card from "./Card";

function Homemobile() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [count, setCount] = useState(0);

  const [otpSent, setOtpSent] = useState(false);
  const [adddata, setAdddata] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  // countapi
  useEffect(() => {
    fetch(`https://api.rcpt.in/rcpt-api/getaddslinkdetails`, {
      method: "POST", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        setAdddata(data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(adddata);
  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(`${baseurl}/rcpt-api/homepage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Adjust the content type as needed
        },
        // You can send an empty object in the request body or omit it if not needed.
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const responseData = await response.text();
        console.log(responseData); // "hi" should be logged to the console
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };
  useEffect(() => {
    fetchDataFromApi();
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleGenerateOTP = () => {
    // You would typically make an API request to your backend here to send the OTP
    // For this example, we'll just set otpSent to true and open the modal.
    setOtpSent(true);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const verifyOtp = () => {
    // You would typically make an API request to your backend to verify the OTP here
    // For this example, we'll simulate OTP verification by checking if the OTP is '1234'.
    if (otp === "1234") {
      setOtpVerified(true);
      closeModal();
    } else {
      // Handle OTP verification failure, show an error message, etc.
      alert("Invalid OTP. Please try again.");
    }
  };
  const navigate = useNavigate();

  // const Gamezop=()=>{
  //    setCount(1);
  //     increment();
  // }
  const Gamezop = (id) => {
    // Make sure the ID is valid before calling increment
    if (id) {
      increment(id);
    } else {
      console.error("ID is not available");
    }
  };
  const increment = (id) => {
    fetch(`https://api.rcpt.in/rcpt-api/increasecount?id=${id}`, {
      method: "GET", // or 'POST', depending on your API requirements
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);

        // Update the state or handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  console.log(count);
  return (
    <>
      <Headermobile />
      <div className="container-fluid">
        <div className="row my-2">
          <div className="col d-flex justify-content-center">
            <a
              href={adddata[0]?.addlink}
              onClick={() => Gamezop(adddata[0]?.id)}
            >
              <img src={adddata[0]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="maincontentbox print-content">
            <div className="forcenter forgap">
              <b>
                <font className="mobilemaintext ">Recharge Receipt</font>{" "}
              </b>
            </div>

            <form>
              <div className="row fromrow">
                <input
                  type="text"
                  className="col-8 mainput"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                />
                <button
                  className="btns submitbtns  col-4"
                  onClick={handleGenerateOTP}
                >
                  Submit
                </button>
              </div>
            </form>
            <div className="d-flex mt-3 mb-3 ml-1">
              <div className="specifwidth ">
                <Link to="/Plan">
                  <button style={{ marginLeft: "0px" }} className="newbtn mt-2">
                    Plans
                  </button>
                </Link>
              </div>
              <div className="specifwidth">
                <Link to="/Stvoffer">
                  <button className="newbtn printbutton mt-2">
                    {" "}
                    STV Offers
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*Desktop */}
        <div className="row mt-2">
          <div className="col d-flex justify-content-center">
            <a
              href={adddata[1]?.addlink}
              onClick={() => Gamezop(adddata[1]?.id)}
            >
              <img src={adddata[1]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
            </a>
          </div>
        </div>

        <div className="row imagerow  mb-3 mt-3 shadow-lg shadow-sm border-2">
          <h1 className="text-danger pe-2 border-1">Trending </h1>
          <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
            <div className="m-2 border p-2">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=EtGe6UDaQUk"
                  playing={true}
                  controls={true}
                  className="react-player"
                  config={{
                    youtube: {
                      playerVars: {
                        autoplay: 1,
                        mute: 1,
                      },
                    },
                  }}
                  width="100%"
                  height="100%"
                />
              </div>

              <div className="m-2">
                <Link
                  to="https://www.mobilemasala.com/numerology/Numerology-by-Srroth-Personality-1-v281321"
                  className="d-flex  flex-column p-3  "
                >
                  <h6>numerology by srroth – personality #1

                  </h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
            <Link
              to="https://www.mobilemasala.com/features/Amitabh-Bachchans-Fav-Recipe---Pasta-Arrabiata-i300448"
              className="d-flex  flex-column p-3  border"
            >
              <div className="m-2">
                <img src={M1} alt="mm1" className="img-fluid"  loading="lazy"/>
              </div>
              <div className="m-2">
                <h6>
              amitabh bachchan's fav recipe - pasta 'arrabiata'
                               </h6>
              </div>
            </Link>
          </div>

          <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
            <Link
              to="https://www.mobilemasala.com/movies/Devara-Part-1-A-record-breaking-pre-release-business%C2%A0locked-for-the-Jr-NTR-starrer-in-Telugu-states-i300490"
              className="d-flex  flex-column p-3  border"
            >
              <div className="m-2">
                <img src={M2} alt="mm1" className="img-fluid"  loading="lazy"/>
              </div>
              <div className="m-2">
                <h6>
devara part 1: a record-breaking pre-release business locked for the jr ntr starrer in telugu states
                               </h6>
              </div>
            </Link>
          </div>

          <div className="col-lg col-md-6 col-sm-12 my-2 p-3">
            <Link
              to="https://www.mobilemasala.com/film-gossip/Lokesh-Kanagaraj-reacts-as-Nagarjunas-Coolie-scene-gets-leaked-2-months-of-hard-work-gone-in-vain-i300678"
              className="d-flex  flex-column p-3  border"
            >
              <div className="m-2">
                <img src={M3} alt="mm1" className="img-fluid" loading="lazy" />
              </div>
              <div className="m-2">
                <h6>
lokesh kanagaraj reacts as nagarjuna's coolie scene gets leaked: '2 months of hard work gone in vain'
                </h6>
              </div>
            </Link>
          </div>
        </div>

        <div className="row m-2 d-none d-md-flex ">
          <div className="col d-flex justify-content-center">
            <a
              href={adddata[2]?.addlink}
              onClick={() => Gamezop(adddata[2]?.id)}
            >
              <img src={adddata[2]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
            </a>
          </div>
        </div>
        {/* mobile */}
        <div className="row m-2  d-md-none">
          <div className="col d-flex  justify-content-center">
            <a
              href={adddata[4]?.addlink}
              onClick={() => Gamezop(adddata[4]?.id)}
            >
              <img src={adddata[4]?.imageurl} alt="mm1" className="img-fluid" loading="lazy" />
            </a>
          </div>
        </div>

        {/* <div className="row imagerow mt-3 mb-3 shadow-lg shadow-sm border-2 d-flex justify-content-center">
          <h1 className="text-dark">Horoscope</h1>
          <div className="col-lg-6 col-md-6 col-sm-12 my-3 p-3 ">
            <Link
              to="https://www.mobilemasala.com/horoscope/Leo-s283"
              className="d-flex  flex-column p-3  border align-items-center justify-content-center"
            >
              <div className="m-2">
                <img src={HH} alt="mm1" className="img-fluid" />
              </div>
              <div className="m-2">
                <h1>Leo</h1>
                <h6>
                  This month, Leos will experience a rejuvenation in personal
                  and professional areas. Relationships flourish, career
                  prospects improve, and financial stability is within reach.
                  Health requires mindful attention.{" "}
                </h6>
              </div>
            </Link>
          </div>
        </div>
        <div className="row my-2 d-none d-md-flex">
          <div className="col d-flex justify-content-center">
            <a
              href={adddata[3]?.addlink}
              onClick={() => Gamezop(adddata[3]?.id)}
            >
              <img src={adddata[3]?.imageurl} alt="mm1" className="img-fluid" />
            </a>
          </div>
        </div> */}

        {/* mobile */}
        {/* <div className="row my-2  d-md-none">
          <div className="col d-flex  justify-content-center">
            <a
              href={adddata[5]?.addlink}
              onClick={() => Gamezop(adddata[5]?.id)}
            >
              <img src={adddata[5]?.imageurl} alt="mm1" className="img-fluid" />
            </a>
          </div>
        </div> */}
      </div>
      <Footer />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="OTP Modal"
      >
        <h2>Enter OTP</h2>
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleOtpChange}
        />
        <button onClick={verifyOtp}>Verify OTP</button>
        {otpVerified && <p>OTP verified successfully!</p>}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
}

export default Homemobile;
